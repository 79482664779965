import { http } from './config'
import authHeader from './auth-header';

export default {


    lista: () => {
        return http.get('/usuario/lista_role', { headers: authHeader() } )
    },

    user: (id) => {
        return http.get('/usuario/roles_user/'+ id, { headers: authHeader() } )
    },
   

}
